import React, {useEffect, useState} from 'react'
import './App.css';
import WarpSpeed from './warpspeed.js';

function App() {
  const [hideStuff] = useState(process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod' ? true : false)
  useEffect(() => {
    if (hideStuff === true) {
	  const x = new WarpSpeed('canvas', {"speed":7,"speedAdjFactor":0.03,"density":4,"shape":"circle","warpEffect":true,"warpEffectLength":2.5,"depthFade":true,"starSize":5,"backgroundColor":"#000000","starColor":"#FFFFFF"})
    }
  }, [hideStuff])
  useEffect(() => {
    document.title = 'Maximum Warp'
  })
  return (
    <>
      {hideStuff === true ?
		<canvas id="canvas" style={{position: 'fixed', top:0, left: 0, width: '100%', height: '100%'}}></canvas>
	  :
	    'hello there'
	  }
	</>  
  );
}

export default App;
